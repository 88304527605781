require("./bootstrap");
//import http from "./axios";
import { createApp, defineAsyncComponent, defineModel } from "vue";
import moment from "moment";
//import store from "./store";
//import BootstrapVueNext from 'bootstrap-vue-next';

import { createBootstrap } from "bootstrap-vue-next";

// Add the necessary CSS
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

import { BootstrapIconsPlugin } from "bootstrap-icons-vue";
import ScrollToTop from "./components/ScrollToTop.vue";
import Hotline from "./components/Hotline.vue";
import Breadcrumb from "./components/home/Breadcrumb.vue";
//import AmLich from "./components/home/AmLich.vue";
//const ElementPlus = defineAsyncComponent(()=> import('element-plus')) ;
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

import http from "./fromFetch";

const SummernoteEditor = defineAsyncComponent(() =>
    import("./components/SummernoteEditor.vue")
);
const IconFload = defineAsyncComponent(() =>
    import("./components/IconFload.vue")
);
const VideoPlayerPip = defineAsyncComponent(() =>
    import("./components/VideoPlayerPip.vue")
);
//const AmLich = defineAsyncComponent(()=> import('./components/home/AmLich.vue'));
import AmLich from "./components/home/AmLich.vue";

//import BootstrapTable from "./plugins/table";

//const BootstrapTable = defineAsyncComponent(()=> import("./plugins/table");
const izoomfancybox = defineAsyncComponent(() =>
    import("./components/izoomfancybox.vue")
);
const InputNumber = defineAsyncComponent(() =>
    import("./components/InputNumber.vue")
);
const SelectImage = defineAsyncComponent(() =>
    import("./components/SelectImage.vue")
);
const MenuBarHome = defineAsyncComponent(() =>
    import("./components/MenuBar.vue")
);
const CustomInput = defineAsyncComponent(() =>
    import("./components/CustomInput.vue")
);
//const MarqueeText  = defineAsyncComponent(()=> import('vue-marquee-text-component'));
//import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
//const InnerImageZoom = defineAsyncComponent(()=> import('vue-inner-image-zoom'));

//const VueImageZoomer = defineAsyncComponent(()=> import('./components/VueImageZoomer.vue'));
//import { VueImageZoomer } from 'vue-image-zoomer'
//import 'vue-image-zoomer/dist/style.css';

//import InnerImageZoom from 'vue-inner-image-zoom';
//const Vue3Marquee = defineAsyncComponent(()=> import('vue3-marquee'));
//import 'mdb-vue-ui-kit/css/mdb.min.css';
import MarqueeText from "./components/MarqueeText.vue";

// <div class="ck-main-container">
//     <div class="editor-container editor-container_classic-editor" ref="editorContainerElement">
//         <div class="editor-container__editor">
//             <div ref="editorElement">
//                 <ckeditor v-if="isCkLayoutReady" v-model="ckeditorConfig.initialData"
//                     :editor="ckeditor" :config="ckeditorConfig" />
//             </div>
//         </div>
//     </div>
// </div>

import { CkeditorPlugin } from "@ckeditor/ckeditor5-vue";
import {
    ClassicEditor,
    Alignment,
    Autoformat,
    AutoImage,
    AutoLink,
    Autosave,
    Base64UploadAdapter,
    BlockQuote,
    Bold,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontSize,
    GeneralHtmlSupport,
    Heading,
    Highlight,
    HorizontalLine,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsert,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    List,
    ListProperties,
    MediaEmbed,
    Paragraph,
    PasteFromOffice,
    RemoveFormat,
    SelectAll,
    SourceEditing,
    SpecialCharacters,
    SpecialCharactersCurrency,
    Strikethrough,
    Subscript,
    Superscript,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    TodoList,
    Underline,
    Undo,
} from "ckeditor5";

//import { Ckeditor } from "@ckeditor/ckeditor5-vue";
import "ckeditor5/ckeditor5.css";
//import { Vue3Marquee } from 'vue3-marquee'
//import 'vue3-marquee/dist/style.css'
// const PdfViewer = defineAsyncComponent(() =>
//     import("./components/PdfViewer.vue")
// );
const pdf = defineAsyncComponent(() => import("pdfvuer"));
//import MenuBar from "./components/MenuBar.vue";
import VueCookies from "vue-cookies";
moment.locale("id");
let numberFormat = function (value) {
    let val = (value / 1).toFixed(0).replace(".", ",");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
import { createI18n } from "vue-i18n";
const numberFormats = {
    "en-US": {
        currency: {
            style: "currency",
            currency: "USD",
        },
        decimal: {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
    },
    "vi-VN": {
        currency: {
            style: "currency",
            currency: "VNP",
            currencyDisplay: "symbol",
        },
        decimal: {
            style: "decimal",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        },
    },
};
const i18n = createI18n({
    locale: "vi-VN",
    numberFormats,
});
//import InputMask from 'primevue/inputmask';
const InputMask = defineAsyncComponent(() => import("primevue/inputmask"));
const app = createApp({
    mixins: [appMixin, myMixin],
    components: {
        //BootstrapTable,
        ScrollToTop,
        SummernoteEditor,
        MarqueeText,
        izoomfancybox, //xzoom
        VideoPlayerPip,
        //VideoPlayer,
        Hotline,
        //ckeditor: Ckeditor,
        Breadcrumb,
        IconFload,
        //CkeditorVue,
        //InnerImageZoom,
        //VueImageZoomer,
        MenuBarHome,
        AmLich,
        //PdfViewer,
        pdf,
        InputNumber,
        CustomInput,
        //Vue3Marquee,
    },
    data() {
        return {
            isMobile: false,
            ckeditor: ClassicEditor,
            ckeditorConfig: null,
            isCkLayoutReady: false,
        };
    },
    mounted() {
        this.onScreenResize();
        window.addEventListener("resize", this.onScreenResize, {
            passive: true,
        });
        this.ckeditorConfig = {
            toolbar: {
                items: [
                    "undo",
                    "redo",
                    "|",
                    "sourceEditing",
                    "selectAll",
                    "|",
                    "heading",
                    "|",
                    "fontSize",
                    "fontColor",
                    "fontBackgroundColor",
                    "|",
                    "bold",
                    "italic",
                    "underline",
                    "strikethrough",
                    "subscript",
                    "superscript",
                    "removeFormat",
                    "|",
                    "specialCharacters",
                    "horizontalLine",
                    "link",
                    "insertImage",
                    "mediaEmbed",
                    "insertTable",
                    "highlight",
                    "blockQuote",
                    "|",
                    "alignment",
                    "|",
                    "bulletedList",
                    "numberedList",
                    "todoList",
                    "outdent",
                    "indent",
                ],
                shouldNotGroupWhenFull: false,
            },
            plugins: [
                Alignment,
                Autoformat,
                AutoImage,
                AutoLink,
                Autosave,
                Base64UploadAdapter,
                BlockQuote,
                Bold,
                Essentials,
                FontBackgroundColor,
                FontColor,
                FontSize,
                GeneralHtmlSupport,
                Heading,
                Highlight,
                HorizontalLine,
                ImageBlock,
                ImageCaption,
                ImageInline,
                ImageInsert,
                ImageInsertViaUrl,
                ImageResize,
                ImageStyle,
                ImageTextAlternative,
                ImageToolbar,
                ImageUpload,
                Indent,
                IndentBlock,
                Italic,
                Link,
                LinkImage,
                List,
                ListProperties,
                MediaEmbed,
                Paragraph,
                PasteFromOffice,
                RemoveFormat,
                SelectAll,
                SourceEditing,
                SpecialCharacters,
                SpecialCharactersCurrency,
                Strikethrough,
                Subscript,
                Superscript,
                Table,
                TableCaption,
                TableCellProperties,
                TableColumnResize,
                TableProperties,
                TableToolbar,
                TextTransformation,
                TodoList,
                Underline,
                Undo,
            ],
            fontSize: {
                options: [10, 12, 14, "default", 18, 20, 22],
                supportAllValues: true,
            },
            heading: {
                options: [
                    {
                        model: "paragraph",
                        title: "Paragraph",
                        class: "ck-heading_paragraph",
                    },
                    {
                        model: "heading1",
                        view: "h1",
                        title: "Heading 1",
                        class: "ck-heading_heading1",
                    },
                    {
                        model: "heading2",
                        view: "h2",
                        title: "Heading 2",
                        class: "ck-heading_heading2",
                    },
                    {
                        model: "heading3",
                        view: "h3",
                        title: "Heading 3",
                        class: "ck-heading_heading3",
                    },
                    {
                        model: "heading4",
                        view: "h4",
                        title: "Heading 4",
                        class: "ck-heading_heading4",
                    },
                    {
                        model: "heading5",
                        view: "h5",
                        title: "Heading 5",
                        class: "ck-heading_heading5",
                    },
                    {
                        model: "heading6",
                        view: "h6",
                        title: "Heading 6",
                        class: "ck-heading_heading6",
                    },
                ],
            },
            htmlSupport: {
                allow: [
                    {
                        name: /^.*$/,
                        styles: true,
                        attributes: true,
                        classes: true,
                    },
                ],
            },
            image: {
                toolbar: [
                    "toggleImageCaption",
                    "imageTextAlternative",
                    "|",
                    "imageStyle:inline",
                    "imageStyle:wrapText",
                    "imageStyle:breakText",
                    "|",
                    "resizeImage",
                ],
            },
            initialData:
                "<h2>Congratulations on setting up CKEditor 5! 🎉</h2>\n<p>\n    You've successfully created a CKEditor 5 project. This powerful text editor will enhance your application, enabling rich text editing\n    capabilities that are customizable and easy to use.\n</p>",
            link: {
                addTargetToExternalLinks: true,
                defaultProtocol: "https://",
                decorators: {
                    toggleDownloadable: {
                        mode: "manual",
                        label: "Downloadable",
                        attributes: {
                            download: "file",
                        },
                    },
                },
            },
            list: {
                properties: {
                    styles: true,
                    startIndex: true,
                    reversed: true,
                },
            },
            placeholder: "Type or paste your content here!",
            table: {
                contentToolbar: [
                    "tableColumn",
                    "tableRow",
                    "mergeTableCells",
                    "tableProperties",
                    "tableCellProperties",
                ],
            },
        };
        this.isCkLayoutReady = true;
    },
    methods: {
        onScreenResize() {
            this.isMobile = window.innerWidth < 768;
        },
        myResponsive() {
            this.isMobile = !this.isMobile;
        },
    },
    beforeDestroy() {
        if (typeof window !== "undefined") {
            window.removeEventListener("resize", this.onScreenResize, {
                passive: true,
            });
        }
    },
})
    .use(ElementPlus)
    .use(i18n)
    //.use(router)
    .use(CkeditorPlugin)
    .use(VueCookies, { expires: "60d" })
    //.use(Vue3Marquee)
    //.use(BootstrapVueNext)
    .use(createBootstrap({ components: true, directives: true })) // Change this line
    .use(BootstrapIconsPlugin);
app.config.globalProperties.moment = moment;
app.config.globalProperties.$http = http;
app.config.globalProperties.numberFormat = numberFormat;
//app.directive('maska', Maska.maska);
window.app = app.mount("#app");
