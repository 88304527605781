<template>
  <ul v-if="!isEmptyObject" class="main-breadcrumbs">
    <template v-for="(item, index) in items">
      <li v-if="index == 0" class="breadcrumb-item">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.22713 1.41982C8.436 1.22234 8.71255 1.1123 9 1.1123C9.28745 1.1123 9.564 1.22234 9.77288 1.41982L15.2224 6.57119C15.5599 6.88956 15.75 7.33394 15.75 7.79744V14.0637C15.75 14.5112 15.5722 14.9405 15.2557 15.2569C14.9393 15.5734 14.5101 15.7512 14.0625 15.7512H11.8125C11.5908 15.7512 11.3713 15.7075 11.1665 15.6226C10.9617 15.5378 10.7756 15.4134 10.6189 15.2565C10.4621 15.0997 10.3379 14.9136 10.2531 14.7087C10.1684 14.5038 10.1249 14.2843 10.125 14.0626V11.2501C10.125 11.1009 10.0657 10.9578 9.96025 10.8523C9.85476 10.7468 9.71168 10.6876 9.5625 10.6876H8.4375C8.28832 10.6876 8.14524 10.7468 8.03975 10.8523C7.93426 10.9578 7.875 11.1009 7.875 11.2501V14.0626C7.875 14.5101 7.69721 14.9393 7.38074 15.2558C7.06428 15.5723 6.63505 15.7501 6.1875 15.7501H3.9375C3.48995 15.7501 3.06072 15.5723 2.74426 15.2558C2.42779 14.9393 2.25 14.5101 2.25 14.0626V7.79631C2.25 7.33281 2.44125 6.88844 2.77875 6.57007L8.22713 1.41982ZM9 2.23657L3.5505 7.38906C3.49518 7.44151 3.45109 7.50466 3.42092 7.57467C3.39075 7.64467 3.37513 7.72008 3.375 7.79631V14.0626C3.375 14.2118 3.43426 14.3548 3.53975 14.4603C3.64524 14.5658 3.78832 14.6251 3.9375 14.6251H6.1875C6.33668 14.6251 6.47976 14.5658 6.58525 14.4603C6.69074 14.3548 6.75 14.2118 6.75 14.0626V11.2501C6.75 10.8025 6.92779 10.3733 7.24426 10.0568C7.56072 9.74035 7.98995 9.56256 8.4375 9.56256H9.5625C10.0101 9.56256 10.4393 9.74035 10.7557 10.0568C11.0722 10.3733 11.25 10.8025 11.25 11.2501V14.0626C11.25 14.2118 11.3093 14.3548 11.4148 14.4603C11.5202 14.5658 11.6633 14.6251 11.8125 14.6251H14.0625C14.2117 14.6251 14.3548 14.5658 14.4602 14.4603C14.5657 14.3548 14.625 14.2118 14.625 14.0626V7.79631C14.625 7.71989 14.6095 7.64427 14.5793 7.57406C14.5491 7.50385 14.505 7.44052 14.4495 7.38794L9 2.23657Z"
            fill="#B5B5B5"
          />
        </svg>
        <a :href="item.href" :title="item.name"> {{ item.name }} </a>
      </li>
      <li v-else-if="index < items.length - 1" class="breadcrumb-item">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.67461 2.95388C5.88428 2.77417 6.19993 2.79845 6.37964 3.00811L10.3796 7.67478C10.5401 7.86202 10.5401 8.13832 10.3796 8.32557L6.37964 12.9922C6.19993 13.2019 5.88428 13.2262 5.67461 13.0465C5.46495 12.8668 5.44067 12.5511 5.62038 12.3414L9.34147 8.00017L5.62038 3.6589C5.44067 3.44924 5.46495 3.13359 5.67461 2.95388Z"
            fill="#B5B5B5"
          />
        </svg>

        <a :href="item.href" :title="item.name"> {{ item.name }} </a>
      </li>
      <li v-else class="breadcrumb-item breadcrumbs-last-child">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.67461 2.95388C5.88428 2.77417 6.19993 2.79845 6.37964 3.00811L10.3796 7.67478C10.5401 7.86202 10.5401 8.13832 10.3796 8.32557L6.37964 12.9922C6.19993 13.2019 5.88428 13.2262 5.67461 13.0465C5.46495 12.8668 5.44067 12.5511 5.62038 12.3414L9.34147 8.00017L5.62038 3.6589C5.44067 3.44924 5.46495 3.13359 5.67461 2.95388Z"
            fill="#B5B5B5"
          />
        </svg>
        {{ item.name }}
      </li>
    </template>
  </ul>
</template>
<script>
//import 'bootstrap/dist/css/bootstrap.min.css';
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  computed: {
    isEmptyObject() {
      return Object.keys(this.items).length === 0;
    }
  },
  // mounted() {
  //     console.log(this.items);
  // }
};
</script>
<style scoped></style>
